import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mobile-menu"
}
const _hoisted_2 = { class: "mobile-menu__header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mobile-menu__menu-wrapper" }
const _hoisted_5 = { class: "mobile-menu__menu-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.showMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                class: "mobile-menu__header-link",
                to: { name: 'home' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Brewsell ")
                ]),
                _: 1
              }),
              _createElementVNode("div", {
                class: "mobile-menu__header-close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _createElementVNode("img", {
                  src: require('@/assets/X.svg')
                }, null, 8, _hoisted_3)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  class: "mobile-menu__link",
                  to: { name: 'menu' },
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Меню")
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  class: "mobile-menu__link",
                  to: { name: 'vacancies' },
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Вакансии")
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}